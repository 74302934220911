import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Tinh1sContext } from '@techartvn/tinh1s-sdk';
import AppComponent from '../components/AppComponent';
import Layout from '../components/Layout';
import context from '../utils/context';
import { isBrowser } from '../utils/browser';
import SEO from '../components/SEO';

const Wrapper = styled.div`
  padding: 5px 10px 15px;
  overflow: none;
  background: #fff;
  min-height: 100vh;

  > div {
    min-height: calc(100vh - 20px);
    box-sizing: border-box;
  }
`;

const CopyRight = styled.a`
  position: fixed;
  bottom: -1px;
  left: -1px;
  font-size: 10px;
  color: #8a8d8f;
  background: #fff;
  padding: 2px 4px;
  border-top-right-radius: 4px;
  border-top: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
`;

const AppVersion = styled.a`
  position: fixed;
  bottom: -1px;
  right: -1px;
  font-size: 10px;
  color: #8a8d8f;
  background: #fff;
  padding: 2px 4px;
  border-top-left-radius: 4px;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
`;

function AppEmbedded({ data }) {
  const { app } = data;
  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    setBaseUrl(window.location.origin);
  }, []);

  return (
    <Tinh1sContext.Provider value={context}>
      <Layout onlyContent>
        <SEO title={app.name} description={app.description} keywords={app.keywords} />
        {app.ssr || isBrowser() && (
          <Wrapper>
            <AppComponent slug={app.slug} />
            <CopyRight href={baseUrl} target="_blank">Tính 1s © 2021</CopyRight>
            <AppVersion href={`${baseUrl}/apps/${app.fullSlug}`} target="_blank">{app.name} - v{app.version}</AppVersion>
          </Wrapper>
        )}
      </Layout>
    </Tinh1sContext.Provider>
  );
}

AppEmbedded.propTypes = {
  data: PropTypes.shape({
    app: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      version: PropTypes.string,
      fullSlug: PropTypes.string,
    }),
  }).isRequired,
};

export default AppEmbedded;

export const pageQuery = graphql`
  query($rawId: String!) {
    app(rawId: { eq: $rawId }) {
      name
      slug
      version
      fullSlug
    }
  }
`;
